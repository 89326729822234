import client from "../../../client";
import {Page} from "./types";


export const create_page_view = async (): Promise<Page> => {
    console.debug("create_page_view")
    return await client.post(
        '/pages/',
    ).then(res => {
        return res.data
    })
}

export const get_page_by_uuid_view = async (pageUUID: string): Promise<Page> => {
    console.debug(`get_page_by_uuid_view: ${pageUUID}`)
    return await client.get(
        `/pages/${pageUUID}/`
    ).then(res => {
        if (res.status === 404) {
            throw Error();
        }
        return res.data
    })
}

export const update_page_html_view = async (pageUUID: string, html: string): Promise<string> => {
    console.debug(`update_page_html_view: ${pageUUID} ${html}`)
    return await client.patch(
        `/pages/${pageUUID}/`,
        {
            "html": html
        }
    ).then(res => {
        return res.data
    })
}
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_page_by_uuid_view, update_page_html_view} from "../../../requests/api/v1/pages";
import {Page} from "../../../requests/api/v1/pages/types";
import useWebSocket from "react-use-websocket";
import {get_page_ws_url} from "../../../config";
import "./index.scss"
import {Helmet} from "react-helmet";

export default function PagePage() {
    const [page, setPage] = useState<Page>();
    const { pageUUID } = useParams() as { pageUUID: string }
    const { sendJsonMessage, lastJsonMessage, readyState} = useWebSocket<{
        type: string,
        html: string
    }>(
        get_page_ws_url(pageUUID),
        {
          share: false,
          shouldReconnect: () => true,
        },
    )

    useEffect(() => {
        console.debug(`Page UUID: ${pageUUID}`)
        if (!pageUUID) {
            console.debug("Redirecting to home page.")
            window.location.href = "/"
        } else {
            console.debug("Starting page fetching.")
            get_page_by_uuid_view(pageUUID).then(res => {
                console.debug("Page fetched:")
                console.debug(res)
                setPage(res)
                console.debug("Page is set.")
            })
        }
    }, [])

    useEffect(() => {
        if (page) {
            if (!lastJsonMessage || !lastJsonMessage.type) {
                return;
            }
            if (lastJsonMessage.type === 'page-update') {
                const newPage: Page = {
                    ...page,
                    html: lastJsonMessage.html
                }
                setPage(newPage)
            }
        }
    }, [lastJsonMessage]);

    return (
        <main className="page-editor-page">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>HTML LIVE</title>
                <link rel="canonical" href="https://htmls.live"/>
                <meta
                    name="description"
                    content="HTML LIVE. Here you can create a web page in one click. Without registration. Completly free."
                />
            </Helmet>
            <div
                 dangerouslySetInnerHTML={{__html: page?.html || ""}}>
            </div>
        </main>
    )
}
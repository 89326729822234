import {create_page_view} from "../../../requests/api/v1/pages";
import Header from "../../parts/Header";
import { Helmet } from "react-helmet";


export default function HomePage() {

    const onNewPage = async () => {
        const page = await create_page_view()
        window.location.href = `/${page.uuid}/editor`
    }

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>HTML LIVE</title>
                <link rel="canonical" href="https://htmls.live"/>
                <meta
                    name="description"
                    content="HTML LIVE. Here you can create a web page in one click. Without registration. Completly free."
                />
            </Helmet>
            <h1>{"<h1>HTMLS.LIVE</h1>"}</h1>
            <p>Welcome to HTMLS.live. Here you can create a web page in one click. Without registration. Completly free.</p>
            <button onClick={onNewPage}>New page</button>
        </main>
    )
}
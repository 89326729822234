import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_page_by_uuid_view, update_page_html_view} from "../../../requests/api/v1/pages";
import {Page} from "../../../requests/api/v1/pages/types";
import useWebSocket from "react-use-websocket";
import {get_page_ws_url} from "../../../config";
import CodeEditor from '@uiw/react-textarea-code-editor';
import "./index.scss"
import Header from "../../parts/Header";
import {Helmet} from "react-helmet";

export default function PageEditorPage() {
    const [page, setPage] = useState<Page>();
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const { pageUUID } = useParams() as { pageUUID: string }
    const [ pageHTML, setPageHTML ] = useState<string>('')
    const [timeoutID, setTimeoutID] = useState<ReturnType<typeof setTimeout> | null>(null);
    const { sendJsonMessage, lastJsonMessage, readyState} = useWebSocket<{
        type: string,
        html: string
    }>(
        get_page_ws_url(pageUUID),
        {
          share: false,
          shouldReconnect: () => true,
        },
    )

    useEffect(() => {
        console.debug(`Page UUID: ${pageUUID}`)
        if (!pageUUID) {
            console.debug("Redirecting to home page.")
            window.location.href = "/"
        } else {
            console.debug("Starting page fetching.")
            get_page_by_uuid_view(pageUUID).then(res => {
                console.debug("Page fetched:")
                console.debug(res)
                setPage(res)
                setPageHTML(res.html)
                console.debug("Page is set.")
            })
        }
    }, [])

    useEffect(() => {
        if (page) {
            if (!lastJsonMessage || !lastJsonMessage.type) {
                return;
            }
            if (lastJsonMessage.type === 'page-update') {
                const newPage: Page = {
                    ...page,
                    html: lastJsonMessage.html
                }
                setPage(newPage)
            }
        }
    }, [lastJsonMessage]);

    const sendHTMLUpdate = () => {
        console.debug(`Sending HTML: ${pageHTML}`)
        update_page_html_view(pageUUID, pageHTML).then(res => {
            console.debug("HTML send and response received:")
            console.debug(res)
        })
    }

    useEffect(() => {
        console.debug("HTML update handling.")
        if (firstRender) {
            console.debug("HTML update skipped on first render.")
            setFirstRender(false)
        } else {
            if (timeoutID) {
                console.debug(`Clearnign timeout to update HTML: ${timeoutID}`)
                clearTimeout(timeoutID);
                setTimeoutID(null);
            }
            console.debug("Setting timeout to update HTML.")
            setTimeoutID(setTimeout(sendHTMLUpdate, 500))
        }
    }, [pageHTML]);

    return (
        <main className="page-editor-page">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Editor | HTML LIVE</title>
                <meta
                    name="description"
                    content="HTML LIVE. Here you can create a web page in one click. Without registration. Completly free."
                />
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
            <Header/>
            <div className="page-editor-page__editor">
                <div className="page-editor-page__editor-side">
                    <div className="page-editor-page__editor-code">
                        <CodeEditor
                            value={pageHTML}
                            onChange={e => setPageHTML(e.target.value)}
                            language={"html"}
                            prefixCls="page-editor-page__editor-code-widget w-tc-editor"
                        />
                    </div>
                </div>
                <div className="page-editor-page__editor-side">
                    <div className="page-editor-page__editor-preview"
                         dangerouslySetInnerHTML={{__html: page?.html || ""}}>
                    </div>
                </div>
            </div>
        </main>
    )
}
import "./index.scss"
import {useParams} from "react-router-dom";

export default function Header() {
    const { pageUUID } = useParams() as { pageUUID?: string }

    return (
        <header className="header">
            <nav className="header__navigation">
                <a className="header__navigation-item" href="/">Home page</a>
            {pageUUID && (
                <>
                    <a className="header__navigation-item" href={`/${pageUUID}`} target={"_blank"}>Open view</a>
                </>
            )}
            </nav>
        </header>
    )
}
import {
  createBrowserRouter,
} from "react-router-dom";
import HomePage from "../pages/HomePage";
import PageEditorPage from "../pages/PageEditorPage";
import PagePage from "../pages/PagePage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/:pageUUID/editor",
    element: <PageEditorPage />
  },
  {
    path: "/:pageUUID",
    element: <PagePage />
  }
]);

export default router;
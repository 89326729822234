export const API_HOST = "localhost"
export const API_PORT = 22332
export const API_ROUTE = '/api/v1'
export const API_SSL: boolean = false


export const get_api_url = () => {
    let url = ''
    if (API_SSL) {
        url += 'https://'
    } else {
        url += 'http://'
    }
    url += API_HOST
    if (API_PORT) {
        url += `:${API_PORT}`
    }
    url += API_ROUTE
    return url
}

export const get_page_ws_url = (pageUUID: string) => {
    let url = ''
    if (API_SSL) {
        url += 'wss://'
    } else {
        url += 'ws://'
    }
    url += API_HOST
    if (API_PORT) {
        url += `:${API_PORT}`
    }
    url += `/ws/${pageUUID}/`
    return url
}